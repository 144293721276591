import React, { useState, useEffect } from "react";
import { auth } from "./config/firebase";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Flex, Stack, Spinner } from "@chakra-ui/react";
import AuthRoute from "./components/AuthRoute";
import routes from "./config/routes";
import logging from "./config/logging";

export interface IApplicationProps {}

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        logging.info("User detected.");
      } else {
        logging.info("No user detected");
      }

      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
      >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />

      </Flex>
    );
  }

  return (
    <div>
      <Router>
        <Routes>
          {routes.map((route, index) =>
            route.protected ? (
              <Route
                key={index}
                path={route.path}
                element={
                  <AuthRoute>
                    <route.element />
                  </AuthRoute>
                }
              />
            ) : (
              <Route
                key={index}
                path={route.path}
                element={<route.element />}
              />
            )
          )}
        </Routes>
      </Router>
    </div>
  );
};

export default Application;
