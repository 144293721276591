import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import IPageProps from "../interfaces/page";
import logging from "../config/logging";

const LogoutPage: React.FunctionComponent<IPageProps> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.currentUser) {
      signOut(auth).then(() => {
        logging.info("User signed out");
        navigate("/");
      });
    } else {
      navigate("/");
    }
  }, [navigate]);

  return <p>Logged out.</p>;
};

export default LogoutPage;
