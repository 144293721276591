import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithCredential,
  PhoneAuthProvider,
  updateProfile,
} from "firebase/auth";
import IPageProps from "../interfaces/page";
import logging from "../config/logging";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  InputLeftAddon,
  Stack,
  Box,
  Avatar,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";

const LoginPage: React.FunctionComponent<IPageProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [codeStage, setCodeStage] = useState<boolean>(false);
  const [nicknameStage, setNicknameStage] = useState<boolean>(false);
  const [phoneNum, setPhoneNum] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const ndate = new Date();
  const hours = ndate.getHours();
  const partOfDay = hours < 12 ? "ранок" : hours < 18 ? "день" : "вечір";

  const handlePhoneNumChange = (e: ChangeEvent<HTMLInputElement>) =>
    setPhoneNum(e.target.value);
  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCode(e.target.value);
  const handleNicknameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setNickname(e.target.value);

  const handlePhoneSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    // validate phone number
    const cleaned = phoneNum.replace(/\D/g, "");
    const phoneRegex = /^\d{9}$/;
    if (!cleaned.match(phoneRegex)) {
      setError("Невірний формат телефону");
      return;
    }
    const fullPhoneNum = `+380${cleaned}`;

    setIsLoading(true);
    signInWithPhoneNumber(
      auth,
      fullPhoneNum,
      new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
        },
        auth
      )
    )
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setIsLoading(false);
        setError(null);
        setCodeStage(true);
        logging.info(confirmationResult, "signInWithPhoneNumber");
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        // Error; SMS not sent
        setError(error.message);
        setIsLoading(false);
      });
  };

  const handleCodeSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    // validate code
    logging.info(code, "code");
    const cleaned = code.replace(/\D/g, "");
    const codeRegex = /^\d{6}$/;
    if (!cleaned.match(codeRegex)) {
      setError("Невірний формат коду");
      return;
    }

    setIsLoading(true);
    var credential = PhoneAuthProvider.credential(
      window.confirmationResult.verificationId,
      cleaned
    );
    logging.info(credential, "credential");
    signInWithCredential(auth, credential)
      .then((user) => {
        // User signed in successfully.
        // Check if user has displayName/nickname already
        if (user.user.displayName) {
          logging.info(user, "user");
          navigate("/");
        } else {
          setIsLoading(false);
          setError(null);
          setCodeStage(false);
          setNicknameStage(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        logging.info(error, "error");
        if (error.code.includes("auth/invalid-verification-code")) {
          setError("Невірний код");
        } else {
          setError(error.message);
        }
      });
  };

  const handleNicknameSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    // nickname
    setIsLoading(true);
    logging.info(nickname, "nickname");
    const cleaned = nickname.replace(/\s/g, "");
    updateProfile(auth.currentUser!, {
      displayName: cleaned,
    })
      .then(() => {
        logging.info("User profile updated");
        navigate("/");
      })
      .catch((error) => {
        setIsLoading(false);
        logging.info(error, "error");
        setError(error.message);
      });
  };

  useEffect(() => {
    if (auth.currentUser) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="blue.500" />
        <Heading color="blue.400">Добрий {partOfDay}!</Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          {!codeStage && !nicknameStage && (
            <form onSubmit={handlePhoneSubmit}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="whiteAlpha.900"
                boxShadow="md"
              >
                <FormControl isRequired isInvalid={error !== null}>
                  <InputGroup>
                    <InputLeftAddon children="+380" />
                    <Input
                      type="tel"
                      placeholder="номер телефону"
                      value={phoneNum}
                      onChange={handlePhoneNumChange}
                    />
                  </InputGroup>
                  {error && <FormErrorMessage>{error}</FormErrorMessage>}
                </FormControl>
                <Button
                  id="sign-in-button"
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  width="full"
                  isLoading={isLoading}
                >
                  Авторизуватись
                </Button>
              </Stack>
            </form>
          )}
          {codeStage && (
            <form onSubmit={handleCodeSubmit}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="whiteAlpha.900"
                boxShadow="md"
              >
                <FormControl isRequired isInvalid={error !== null}>
                  <Input
                    type="number"
                    placeholder="код з SMS"
                    value={code}
                    onChange={handleCodeChange}
                  />
                  {error && <FormErrorMessage>{error}</FormErrorMessage>}
                </FormControl>
                <Button
                  id="sign-in-button"
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  width="full"
                  isLoading={isLoading}
                >
                  Відправити код
                </Button>
              </Stack>
            </form>
          )}
          {nicknameStage && (
            <form onSubmit={handleNicknameSubmit}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="whiteAlpha.900"
                boxShadow="md"
              >
                <FormControl isRequired isInvalid={error !== null}>
                  <Input
                    type="text"
                    placeholder="Нікнейм"
                    value={nickname}
                    onChange={handleNicknameChange}
                  />
                  {error && <FormErrorMessage>{error}</FormErrorMessage>}
                </FormControl>
                <Button
                  id="sign-in-button"
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  width="full"
                  isLoading={isLoading}
                >
                  Зареєструватись
                </Button>
              </Stack>
            </form>
          )}
        </Box>
      </Stack>
    </Flex>
  );
};

export default LoginPage;
