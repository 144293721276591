const config = {
  firebase: {
    apiKey: "AIzaSyAVqQUmU9UHlhlHzkOpyXDbgMTneax1u1s",
    authDomain: "palne-ml.firebaseapp.com",
    projectId: "palne-ml",
    storageBucket: "palne-ml.appspot.com",
    messagingSenderId: "634324420519",
    appId: "1:634324420519:web:bc2ab796aa5c3b810d9882",
    measurementId: "G-H5WZ8M2756",
  },
};

export default config;
