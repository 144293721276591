import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import useGeolocation, {
  EnrichedGeolocationCoordinates,
} from "react-hook-geolocation";
import { auth } from "../config/firebase";
import IPageProps from "../interfaces/page";
import { LatLngTuple, Icon } from "leaflet";

import logging from "../config/logging";

const HomePage: React.FunctionComponent<IPageProps> = (props) => {
  const onGeoLocationUpdate = (geolocation: EnrichedGeolocationCoordinates) => {
    logging.info(geolocation, "geolocation");
    position = (
      !geoLocation.error
        ? [geoLocation.latitude || 49.23, geoLocation.longitude || 28.46]
        : [49.23, 28.46]
    ) as LatLngTuple;
  };

  const geoLocation = useGeolocation(
    {
      enableHighAccuracy: false,
      maximumAge: 0,
      timeout: 10000,
    },
    //onGeoLocationUpdate
  );

  let position = (
    !geoLocation.error
      ? [geoLocation.latitude || 49.23, geoLocation.longitude || 28.46]
      : [49.23, 28.46]
  ) as LatLngTuple;

  const gasStationIcon = new Icon({
    iconUrl: "fuel-pump.png",
    iconSize: [50, 50],
  });


  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <p>Map</p>
      <Box height="100%" width="100%">
        <MapContainer
          center={position}
          zoom={14}
          scrollWheelZoom={false}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={gasStationIcon}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </Box>
    </Flex>
  );
};

export default HomePage;
