import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, PhoneAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import config from "./config";

// Initialize Firebase
const app = firebase.initializeApp(config.firebase);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
auth.useDeviceLanguage();

export const Providers = {
  phone: new PhoneAuthProvider(auth),
};

export const db = getFirestore(app);
export default app;
