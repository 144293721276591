import IRoute from "../interfaces/route";
import HomePage from "../pages/home";
import LoginPage from "../pages/login";
import LogoutPage from "../pages/logout";

const routes: IRoute[] = [
  {
    path: "/",
    element: HomePage,
    name: "Home Page",
    protected: true,
  },
  {
    path: "/login",
    element: LoginPage,
    name: "Login",
    protected: false,
  },
  {
    path: "/logout",
    element: LogoutPage,
    name: "Logout",
    protected: false,
  },
];

export default routes;
