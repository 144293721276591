import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import logging from "../../config/logging";

export interface IAuthRouteProps {
  children: any;
}

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
  const { children } = props;

  let location = useLocation();

  if (!auth.currentUser) {
    logging.warn("No user detected, redirecting to /login");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <div>{children}</div>;
};

export default AuthRoute;
